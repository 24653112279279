<template>
  <div class="container home">
    <el-carousel height="300px" v-if="imgShow">
      <el-carousel-item v-for="item in imgs" :key="item.adId">
        <img :src="item.img" class="carousel_img" />
      </el-carousel-item>
    </el-carousel>
    <div v-html="content" class="content"></div>
  </div>
</template>
<script>
import { getCarousel, getMenuContent } from "@/api/user.js"; //引入文件
export default {
  data() {
    return {
      imgs: [],
      mennId: "2",
      content: "",
      imgShow: true,
    };
  },
  watch: {
    $route: {
      handler() {
        // 初始化操作，这里边的操作可以把created钩子中的操作复制到这里一份。
        this.mennId = this.$route.query.id ? this.$route.query.id : "2";
        this.getContent();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.getImg();
    // this.getContent();
  },
  methods: {
    getImg() {
      getCarousel().then((res) => {
        this.imgs = res.data.data;
      });
    },
    getContent() {
      getMenuContent({
        article_menu_id: this.mennId,
      }).then((res) => {
        this.content = res.data.data.list[0].content.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
        this.imgShow = res.data.data.list[0].title == "首页" ? true : false;
        console.log(res);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  // width: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
}
.content{
  padding: 0 1rem;
}

.carousel_img {
  width: 100%;
  height: 100%;
}
</style>